import React, { useEffect, useRef, useState } from "react";
import useAxios from '../../../../../hooks/useAxios';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, updateIntegration, setFormData } from '../../../../../store';
import FormSection from "./FormSection"
import { Success, Warning, Confirm } from "../../../../../config/swal";
import { on } from "events";
const UpdateRecord = ({ handleNextStep, optimiserObjects, isFormDisabled, setFormEditable }: any) => {
	//const [FieldsMap, setFieldsMap] = useState([{ value: "" }]);
	const dispatch = useDispatch();
	const initailUpdateObj: any =
		[
			{
				FieldsMap: [{ FormFieldName: "", OptimiserFieldName: "" }],
				ObjectName: "",
				ObjectFields: [],
				SelectedOption: "option1",
				UniqueFields: [{ FormFieldName: '', OptimiserFieldName: '' }],
				validationMessages: {
					ObjectName: '',
					UniqueFields: [{ FormFieldName: '', OptimiserFieldName: '' }],
					FieldsMap: [{ FormFieldName: '', OptimiserFieldName: '' }],
				}
			},
		];

	const [sections, setSections] = useState(initailUpdateObj);
	const [isEdit, setIsEdit] = useState(false);
	const [items, setItems] = useState([]);
	const [filteredObjectFields, setFilteredObjectFields] = useState({});
	//const [optimiserObjects, setoptimiserObjects] = useState([]);
	const formData = useSelector((state: RootState) => state.project.formData);
	const integrationData = useSelector((state: RootState) => state.project.integration);
	//let items = formData.Metadata.Content.pages?.map((p: any) => p.elements || []).flat(1) || [];
	//items = items.map((i: any) => ({ ...i, value: i.name, label: i.name }))
	const fieldTypeMapping: any = {
		boolean: ["checkbox"],
		privacypolicy: ["checkbox"],
		termsconditions: ["checkbox"],
		tagbox: ["multiselect", "text", "textarea","multilookup"],
		checkbox: ["multiselect", "text", "textarea","checkbox"],
		monthfield: ["text"],
		fullname: ["text"],
		address: ["text", "textarea", "texteditor"],
		city: ["lookup"],
		country: ["dropdown"],
		dropdown: ["dropdown", "text", "lookup", "multilookup", "objectid"],
		phonenumber: ["phone", "text"],
		datepicker: ["date", "text"],
		emailfield: ["email", "text"],
		text: ["text","objectid"],
		comment: ["textarea","texteditor"],
		file: ["image","file"],
		radiogroup: ["dropdown", "text"],
		number: ["int", "decimal", "text"],
		tel: ["phone", "text"],
		color: ["text"],
		date: ["date", "text"],
		datetime: ["datetime", "text"],
		email: ["email", "text"],
		month: ["text"],
		password: [], // Not applicable
		range: ["int", "decimal", "text"],
		time: ["time"],
		url: ["url", "text"],
		week: ["text"],
	};
	const axios = useAxios();

	const handleDeleteField = (sectionIndex: number, fieldIndex: number) => {
		if (sections[sectionIndex].FieldsMap.length <= 1) {
			Warning({ title: "You cannot delete all fields. Please keep at least one field." })
			return
		}
		const newSections = sections.map((section: any, i: number) => {
			if (i === sectionIndex) {
				const newFieldsMap = section.FieldsMap.filter((_: any, j: number) => j !== fieldIndex);
				return { ...section, FieldsMap: newFieldsMap };
			}
			return section;
		});
		setSections(newSections);
	};
	const handleObjectDelete = (sectionIndex: number) => {
		if (sections.length <= 1) {
			Warning({ title: "You cannot delete all sections. Please keep at least one section." })
			return;
		}
		const newSections2 = sections.filter((_: any, j: number) => j !== sectionIndex);
		setSections(newSections2);
	};
	const handleAddField = (e: any, sectionIndex: number) => {
		e.preventDefault();
		const newSections = sections.map((section: any, i: number) => {
			if (i === sectionIndex) {
				return {
					...section, FieldsMap: [...section.FieldsMap, { FormFieldName: "", OptimiserFieldName: "", IsNew: true }], validationMessages: {
						...section.validationMessages,
						FieldsMap: [...section.validationMessages.FieldsMap, { FormFieldName: '', OptimiserFieldName: '' }]
					}
				};
			}
			return section;
		});
		setSections(newSections);
	};
	useEffect(() => {

		let fitems = formData.Metadata.Content.pages?.map((p: any) => p.elements || []).flat(1) || [];
		//console.log(fitems)
		let newItems: any = [];
		for (const item of fitems) {
			//if (['html', 'image', 'file', 'customlabel'].includes(item.type)) continue;
			switch (item.type) {
				case 'fullname':
					if (item.prefixVisible) newItems.push({ name: `${item.name} (Prefix)`, valueName: `{${item.name}.prefix}`, label: `${item.title} (Prefix)`, type:item.type });
					newItems.push({ name: `${item.name} (First Name)`, valueName: `{${item.name}.firstName}`, label: `${item.title} (First Name)`, type:item.type });
					if (item.middleNameVisible) newItems.push({ name: `${item.name} (Middle Name)`, valueName: `{${item.name}.middleName}`, label: `${item.title} (Middle Name)`, type:item.type })
					newItems.push({ name: `${item.name} (Last Name)`, valueName: `{${item.name}.lastName}`, label: `${item.title} (Last Name)`, type:item.type });
					break;
				case 'address':
					if (item.showStreetAddressLine1 == undefined) newItems.push({ name: `${item.name} (St. Address 1)`, valueName: `{${item.name}.streetAddressLine1}`, label: `${item.title} (St. Address 1)`, type:item.type })
					if (item.showStreetAddressLine2 == undefined) newItems.push({ name: `${item.name} (St. Address 2)`, valueName: `{${item.name}.streetAddressLine2}`, label: `${item.title} (St. Address 2)`, type:item.type })
					if (item.showCity == undefined) newItems.push({ name: `${item.name} (City)`, valueName: `{${item.name}.city}`, label: `${item.title} (City)`, type:'city' })
					if (item.showState == undefined) newItems.push({ name: `${item.name} (State)`, valueName: `{${item.name}.state}`, label: `${item.title} (State)`, type:item.type })
					if (item.showCountry == undefined) newItems.push({ name: `${item.name} (Country)`, valueName: `{${item.name}.country}`, label: `${item.title} (Country)`, type:'country' })
					if (item.showPostalCode == undefined) newItems.push({ name: `${item.name} (Postal Code)`, valueName: `{${item.name}.postalCode}`, label: `${item.title} (Postal Code)`, type:item.type })
					break;
				// case 'customlabel':
				//     newItems.push({name: `${item.labelText || 'Label'}`, valueName: `${item.labelText || 'Label'}`})
				//     newItems.push({name: `${item.subLabelText || 'Sub Label'}`, valueName: `${item.subLabelText || 'Sub Label'}`})
				// break;
				default:
					if(item.inputType) newItems.push({ name: `${item.name}`, valueName: `{${item.name}}`, label: `${item.title}`,type:item.inputType})
					else newItems.push({ name: `${item.name}`, valueName: `{${item.name}}`, label: `${item.title}`,type:item.type})
					break;
			}
		}

		newItems = newItems.map((i: any) => ({ ...i, value: i.name }))
		newItems = newItems.sort((a:any, b:any) => a.label.localeCompare(b.label));
		setItems(newItems);
		if (formData.Settings.Integration && formData.Settings.Integration.Optimiser.update) setIsEdit(true);
		let intSections: any = [];
		if (integrationData && integrationData.update && integrationData.update.length) {
			//intSections = integrationData.update;
			intSections = integrationData.create.map((section: any, i: number) => {
				let validationMessages: any = {
					ObjectName: '',
					UniqueFields: [{ FormFieldName: '', OptimiserFieldName: '' }],
					FieldsMap: [],
				};
				let selectedObject: any = optimiserObjects.find((ob: any) => ob.Name == section.ObjectName);
				section.FieldsMap.forEach((element: any) => {
					validationMessages.FieldsMap.push({ FormFieldName: '', OptimiserFieldName: '' });
				});
				let findSection = integrationData.update.find((o: any) => o.ObjectName == section.ObjectName);
				section = { ...section, UniqueFields: [{ FormFieldName: '', OptimiserFieldName: '' }] }
				if (findSection) {
					let FieldsMap = section.FieldsMap.map((f: any) => {
						let findField = findSection.FieldsMap.find((innerf: any) => innerf.OptimiserFieldName == f.OptimiserFieldName);
						//console.log(findField);
						if (findField) {
							return { ...findField,FormFieldName:f.FormFieldName }
						}
						return { ...f, IsChecked: true };
					})
					section = { ...findSection, FieldsMap: FieldsMap };
				} else {
					let FieldsMap = section.FieldsMap.map((f: any) => {
						return { ...f, IsChecked: true };
					})
					section = { ...section, FieldsMap: FieldsMap, UniqueFields: [{ FormFieldName: '', OptimiserFieldName: '' }], SelectedOption: "option1" }
				}
				// console.log(section.FieldsMap)
				return { ...section, ObjectFields: selectedObject ? selectedObject.Fields.map((f: any) => ({ value: f.Name, label: f.DisplayName + " (" + f.UIDataType + ")", UIDataType:f.UIDataType })) : [], validationMessages: validationMessages };

			});

		} else if (formData.Settings.Integration && formData.Settings.Integration.Optimiser.update) {
			intSections = integrationData.create.map((section: any, i: number) => {
				let validationMessages: any = {
					ObjectName: '',
					UniqueFields: [{ FormFieldName: '', OptimiserFieldName: '' }],
					FieldsMap: [],
				};
				let selectedObject: any = optimiserObjects.find((ob: any) => ob.Name == section.ObjectName);
				section.FieldsMap.forEach((element: any) => {
					validationMessages.FieldsMap.push({ FormFieldName: '', OptimiserFieldName: '' });
				});
				let findSection = formData.Settings.Integration.Optimiser.update.find((o: any) => o.ObjectName == section.ObjectName);
				if (findSection) {
					let FieldsMap = section.FieldsMap.map((f: any) => {
						let findField = findSection.FieldsMap.find((innerf: any) => innerf.OptimiserFieldName == f.OptimiserFieldName);
						if (findField) {
							return { ...findField,FormFieldName:f.FormFieldName}
						}
						return { ...f, IsChecked: true };
					})
					section = { ...findSection, FieldsMap: FieldsMap };
				} else {
					let FieldsMap = section.FieldsMap.map((f: any) => {
						return { ...f, IsChecked: true };
					})
					section = { ...section, FieldsMap: FieldsMap, UniqueFields: [{ FormFieldName: '', OptimiserFieldName: '' }], SelectedOption: "option1" }
				}
				//console.log(section.FieldsMap)
				return { ...section, ObjectFields: selectedObject ? selectedObject.Fields.map((f: any) => ({ value: f.Name, label: f.DisplayName + " (" + f.UIDataType + ")", UIDataType:f.UIDataType })) : [], validationMessages: validationMessages };
			});

			// intSections = formData.Settings.Integration.Optimiser.update.map((section: any, i: number) => {
			// 	let validationMessages: any = {
			// 		ObjectName: '',
			// 		UniqueFields: [{ FormFieldName: '', OptimiserFieldName: '' }], 
			// 		FieldsMap: [],
			// 	};
			// 	let selectedObject: any = optimiserObjects.find((ob: any) => ob.Name == section.ObjectName);
			// 	section.FieldsMap.forEach((element: any) => {
			// 		validationMessages.FieldsMap.push({ FormFieldName: '', OptimiserFieldName: '' });
			// 	});

			// 	return { ...section, ObjectFields:selectedObject? selectedObject.Fields.map((f: any) => ({ value: f.Name, label: f.DisplayName+" (" + f.UIDataType + ")" })):[], validationMessages: validationMessages };
			// });
		} else if (integrationData && integrationData.create && integrationData.create.length) {
			intSections = integrationData.create.map((section: any, i: number) => {
				let FieldsMap = section.FieldsMap.map((f: any) => {
					return { ...f, IsChecked: true };
				})
				return {
					...section, FieldsMap: FieldsMap, SelectedOption: "option1", UniqueFields: [{ FormFieldName: "", OptimiserFieldName: "" }], validationMessages: { ...section.validationMessages, UniqueFields: [{ FormFieldName: "", OptimiserFieldName: "" }] }
				};
			});
			//intSections = {...formData.Settings.Optmiser.Create}
		}
		//console.log(intSections)
		intSections.map((section:any) => {
			setFilteredObjectFields((prevState: any) => ({
				...prevState,
				[section.ObjectName]: section.ObjectFields
			}));
		});
		setSections(intSections);

		// axios.post(`forms/getobjects`).then(data => {
		// 	console.log(data)
		// 	let cdata = data.data.map((d: any) => ({ ...d, value: d.Name, label: d.Name }))
		// 	setoptimiserObjects(cdata);
		// 	return () => {
		// 		//const saveBtn = creator.toolbarItems.find((item: any) => item.id == "save-status");
		// 		//saveBtn.visible = false;
		// 	};
		// })

	}, []);
	const handleObjectChange = (sectionIndex: number, value: any) => {
		if (value) {
			const newSections = sections.map((section: any, i: number) => {
				if (i === sectionIndex) {
					let selectedObject: any = optimiserObjects.find((ob: any) => ob.Name == value);
					return { ...section, UniqueFields: [{ FormFieldName: "", OptimiserFieldName: "", IsNew: true }], ObjectName: value, FieldsMap: [{ FormFieldName: "", OptimiserFieldName: "", IsNew: true }], ObjectFields: selectedObject ? selectedObject.Fields.map((f: any) => ({ value: f.Name, label: f.DisplayName + " (" + f.UIDataType + ")" })) : [] };
				}
				return section;
			});
			setSections(newSections);
		}

	};
	const handleUniqueFieldsChange = (sectionIndex: number, field: any, value: string, fieldType:any) => {
		const newSections = sections.map((section: any, i: number) => {
			if (i === sectionIndex) {
				if(field === "FormFieldName"){
					if(value){
						if (value.endsWith("record _id")){
							const allFields = section.ObjectFields.map((objField: any) => ({
								value: objField.value,
								label: `${objField.label}`,
								UIDataType: objField.UIDataType,
							  }));
				  
							  setFilteredObjectFields((prevState: any) => ({
								...prevState,
								[section.ObjectName]: allFields,
							  }));
				  
							  return {
								...section,
								UniqueFields: [{ ...section.UniqueFields[0], [field]: value }],
							  };
						}else{
							const filterFields = section.ObjectFields.filter((objField: any) => {
								// if (fieldType === "city") {
								// 	if (objField.UIDataType === "lookup") {
								// 		return objField.value === "City";
								// 	}
								// 	return fieldTypeMapping[fieldType]?.includes(objField.UIDataType)
								// }
								return fieldTypeMapping[fieldType]?.includes(objField.UIDataType)
							}).map((f: any) => ({
								value: f.value,
								label: `${f.label}`,
								UIDataType: f.UIDataType
							}));	
							const existingOptimiserFieldName = section.UniqueFields[0].OptimiserFieldName;
							let updatedOptimiserFieldName = "";
							const matchFound = filterFields.some((f: any) => {
							return fieldTypeMapping[fieldType]?.includes(f.UIDataType) && f.value === existingOptimiserFieldName;
							});
							if (matchFound) {
								updatedOptimiserFieldName = existingOptimiserFieldName;
							}
							setFilteredObjectFields((prevState: any) => ({
								...prevState,
								[section.ObjectName]: filterFields
							}));
							return { ...section, UniqueFields: [{ ...section.UniqueFields[0], [field]: value, OptimiserFieldName: updatedOptimiserFieldName }] }; 
						}
					}else{
						setFilteredObjectFields((prevState: any) => ({
							...prevState,
							[section.ObjectName]: section.ObjectFields
						}));
					} 
				}else if(field === "OptimiserFieldName" && !value){
					setFilteredObjectFields((prevState: any) => ({
						...prevState,
						[section.ObjectName]: section.ObjectFields
					}));
				}
				return { ...section, UniqueFields: [{ ...section.UniqueFields[0], [field]: value }] };
			}
			return section;
		});
		setSections(newSections);
		//validateUniqueFields(newSections, sectionIndex, field);
	};
	const validateUniqueFields = (newSections: any, sectionIndex: number, field: any) => {
		newSections = [...newSections];
		newSections[sectionIndex].validationMessages.UniqueFields[0][field] = '';
		if (!newSections[sectionIndex].UniqueFields[0][field] && field == "FormFieldName") {
			newSections[sectionIndex].validationMessages.UniqueFields[0][field] = 'Form Field is required.';
		} else if (!newSections[sectionIndex].UniqueFields[0][field] && field == "OptimiserFieldName") {
			newSections[sectionIndex].validationMessages.UniqueFields[0][field] = 'Optimiser Field is required.';
		}
		setSections(newSections);
	}

	const validateUniqueValues = (newSections2: any, sectionIndex: number = -1) => {
		// Validate unique ObjectName
		let valid = true;
		let newSection = newSections2.map((section: any, index: number) => {
			const validationMessages = {
				ObjectName: '',
				UniqueFields: [{ FormFieldName: '', OptimiserFieldName: '' }]
			};
			if (sectionIndex !== -1) {
				if (sectionIndex == index) {
					if (!section.UniqueFields[0].FormFieldName) {
						validationMessages.UniqueFields[0].FormFieldName = 'Form Field is required.';
						valid = false;
					}
					if (!section.UniqueFields[0].OptimiserFieldName) {
						validationMessages.UniqueFields[0].OptimiserFieldName = 'Optmiser Field is required.';
						valid = false;
					}
					return { ...section, validationMessages: { ...section.validationMessages, UniqueFields: [{ ...validationMessages.UniqueFields[0] }] } }

				} else {
					return { ...section }
				}
			} else {
				if (!section.UniqueFields[0].FormFieldName) {
					validationMessages.UniqueFields[0].FormFieldName = 'Form Field is required.';
					valid = false;
				}
				if (!section.UniqueFields[0].OptimiserFieldName) {
					validationMessages.UniqueFields[0].OptimiserFieldName = 'Optmiser Field is required.';
					valid = false;
				}
				return { ...section, validationMessages: { ...section.validationMessages, UniqueFields: [{ ...validationMessages.UniqueFields[0] }] } }
			}

		})

		// Validate FieldsMap
		setSections(newSection)
		return valid;

	};
	const validateForm = (newSections2: any) => {
		// Validate unique ObjectName
		newSections2 = JSON.parse(JSON.stringify([...newSections2]));
		const newSections = newSections2.map((section: any, sectionIndex: number) => {
			const validationMessages = {
				ObjectName: '',
				FieldsMap: section.FieldsMap.map(() => ({ FormFieldName: '', OptimiserFieldName: '' }))
			};


			// Validate FieldsMap
			const optimiserFieldMap: any = {};
			section.FieldsMap.forEach((FieldsMap: any, groupIndex: number) => {
				const { FormFieldName, OptimiserFieldName } = FieldsMap;
				
				if (!FormFieldName) {
					validationMessages.FieldsMap[groupIndex].FormFieldName = 'Form Field is required.';
				}
				if (!OptimiserFieldName) {
					validationMessages.FieldsMap[groupIndex].OptimiserFieldName = 'Optimiser Field is required.';
				}
				if (OptimiserFieldName) {
					if (!optimiserFieldMap[OptimiserFieldName]) {
						optimiserFieldMap[OptimiserFieldName] = true;
					} else if (optimiserFieldMap[OptimiserFieldName]) {
						validationMessages.FieldsMap[groupIndex].OptimiserFieldName = `Optimiser Field '${OptimiserFieldName}' is already mapped to a different Form Field '${optimiserFieldMap[OptimiserFieldName]}'.`;
					}
				}
			});

			return {
				...section,
				validationMessages: { ...section.validationMessages, FieldsMap: validationMessages.FieldsMap }

			};
		});

		setSections(newSections);
		const hasErrors = newSections.some((section: any) => {
			return section.validationMessages.FieldsMap.some((group: any) => group.FormFieldName || group.OptimiserFieldName);
		});
		return !hasErrors;
	};
	const handleSubmit = async (e: any) => {
		e.preventDefault();
		//if (validateUniqueValues(sections) && validateForm(sections)) {
		if (validateForm(sections)) {
			let nemap = sections.map((o: any, i:number) => {
                const applyFormValidation = integrationData.create[i]?.ApplyFormValidation;
				return { ...o, IsNew: undefined, UniqueFields: [{ ...o.UniqueFields[0], IsNew: undefined }], FieldsMap: o.FieldsMap.map((f: any) => ({ ...f, IsNew: undefined })), validationMessages: undefined, ObjectFields: undefined, ApplyFormValidation:applyFormValidation }
			})
			//let updateObject = { ...sections, validationMessages: undefined, ObjectsMap: nemap, ObjectFields: undefined }
			const createSections = integrationData.create.map((section: any, i: number) => {
				return { ObjectName: section.ObjectName, FieldsMap: section.FieldsMap, ApplyFormValidation:section.ApplyFormValidation };
			});
			await axios.patch(`forms/integration-setting/` + formData._id, { target: "Optimiser", value: { create: createSections, update: nemap, action: "update" } });
			dispatch(updateIntegration({ ...integrationData, update: sections }));
			//formData.Settings.Integration = {create: createSections, update: nemap, action: "update"}
			dispatch(setFormData({ Settings: { ...formData.Settings, Integration: { "Optimiser": { create: createSections, update: nemap, action: "update" } } } }));
			Success({ title: "Settings saved" })
			handleNextStep(1);
			setFormEditable(false);
			console.log("Form is valid");
			// Submit the form or perform further actions
		} else {
			console.log("Form is invalid");
		}
	};

	const handleRadioChange = (sectionIndex: number, value: string) => {
		const newSections = sections.map((section: any, i: number) => {
			if (i === sectionIndex) {
				return { ...section, SelectedOption: value };
			}
			return section;
		});
		//console.log(newSections);
		setSections(newSections);
	};
	
	const handleSliderChange = (sectionIndex: number, fieldIndex: number, value: string) => {
		const newSections = sections.map((section: any, i: number) => {
			if (i === sectionIndex) {
				//return { ...section, SelectedOption: value };
				const FieldsMap = section.FieldsMap.map((field: any, j: number) => {
					if (j === fieldIndex) {
						return { ...field, IsChecked: value };
					}
					return field;
				});
				return { ...section, FieldsMap: FieldsMap };
			}
			return section;
		});
		//console.log(newSections);
		setSections(newSections);
	};

	const handleBackButton = () => {
		dispatch(updateIntegration({ ...integrationData, update: sections }));
		handleNextStep(1)
		setFormEditable(false)
	}
	const handleInputChange = (sectionIndex: number, groupIndex: number, field: any, value: string) => {
		const newSections = sections.map((section: any, i: number) => {
			if (i === sectionIndex) {
				const newGroups = section.FieldsMap.map((group: any, j: number) => {
					if (j === groupIndex) {
						return { ...group, [field]: value };
					}
					return group;
				});
				return { ...section, FieldsMap: newGroups };
			}
			return section;
		});
		//console.log(newSections);
		//setSections(newSections);
		//validateUniqueValues(newSections,groupIndex, field, value);
		validateGroupFieldValues(newSections, sectionIndex, groupIndex, field, value);
	};
	const validateGroupFieldValues = (newSections: any, sectionIndex: number, groupIndex: number = -1, field: any = null, value: string = "") => {
		newSections = JSON.parse(JSON.stringify([...newSections]));
		const { FormFieldName, OptimiserFieldName } = newSections[sectionIndex].FieldsMap[groupIndex];
		if (field == "FormFieldName") newSections[sectionIndex].validationMessages.FieldsMap[groupIndex].FormFieldName = '';
		if (field == "OptimiserFieldName") newSections[sectionIndex].validationMessages.FieldsMap[groupIndex].OptimiserFieldName = '';

		if (field == "FormFieldName" && !FormFieldName) {
			newSections[sectionIndex].validationMessages.FieldsMap[groupIndex].FormFieldName = 'Form Field is required.';
		} else if (field == "OptimiserFieldName" && !OptimiserFieldName) {
			newSections[sectionIndex].validationMessages.FieldsMap[groupIndex].OptimiserFieldName = 'Optimiser Field is required.';
		} else if (field == "OptimiserFieldName" && OptimiserFieldName) {
			let optimiserFieldMap: any = {};
			newSections[sectionIndex].FieldsMap.forEach((element: any, index: number) => {
				if (index != groupIndex) {
					if (!optimiserFieldMap[element.OptimiserFieldName]) {
						optimiserFieldMap[element.OptimiserFieldName] = true;
					}
				}

			});

			if (optimiserFieldMap[OptimiserFieldName]) {
				newSections[sectionIndex].validationMessages.FieldsMap[groupIndex].OptimiserFieldName = `Optimiser Field '${OptimiserFieldName}' is already mapped to a different Form Field '${optimiserFieldMap[OptimiserFieldName]}'.`;
			}
		}
		setSections(newSections);
	}
	const resetCreatForm = async (e: any) => {
		e.preventDefault();
		//Warning({ title: "Cancel" });
		let data = await Confirm({
			html: `<span style='font-size:15px; color:#686868;'>Are you sure you want to discard the Integration set-up?</span>`,
			confirmButtonText: `<span style='font-size:14px'>Discard  Changes</span>`,
			cancelButtonText: `<span style='font-size:14px'>Go Back</span>`,

		})
		if (data.isConfirmed) {
			let intSections: any = [];
			if (integrationData && integrationData.update && integrationData.update.length) {
				intSections = integrationData.update;
			} else if (formData.Settings.Integration && formData.Settings.Integration.Optimiser.update) {
				intSections = formData.Settings.Integration.Optimiser.update.map((section: any, i: number) => {
					let validationMessages: any = {
						ObjectName: '',
						UniqueFields: [{ FormFieldName: '', OptimiserFieldName: '' }],
						FieldsMap: [],
					};
					let selectedObject: any = optimiserObjects.find((ob: any) => ob.Name == section.ObjectName);
					section.FieldsMap.forEach((element: any) => {
						validationMessages.FieldsMap.push({ FormFieldName: '', OptimiserFieldName: '' });
					});
					return { ...section, ObjectFields: selectedObject ? selectedObject.Fields.map((f: any) => ({ value: f.Name, label: f.DisplayName + " (" + f.UIDataType + ")" })) : [], validationMessages: validationMessages };
				});
			} else if (integrationData && integrationData.create && integrationData.create.length) {
				intSections = integrationData.create.map((section: any, i: number) => {
					const FieldsMap = section.FieldsMap.map((field: any, j: number) => {
						return { ...field, IsChecked: true }
					})
					return {
						...section, FieldsMap: FieldsMap, SelectedOption: "option1", UniqueFields: [{ FormFieldName: "", OptimiserFieldName: "" }], validationMessages: { ...section.validationMessages, UniqueFields: [{ FormFieldName: "", OptimiserFieldName: "" }] }
					};
				});
				//intSections = {...formData.Settings.Optmiser.Create}
			}

			setSections(intSections);
			//setActionName("create");
			//setSections(initialSections);
			//setSections(intSections);
		}
	}
	const getPreviousSectionIds = (sectionIndex: number) => {
		let items = [];
		if (sectionIndex != 0) {
			for (let index = 0; index < sectionIndex; index++) {
				if (sections[index].ObjectName) items.push({ value: `${sections[index].ObjectName} record _id`, label: `${sections[index].ObjectName} record _id` });
			}
		}
		return items;
	}
	const handleAddSection = (e: any) => {
		e.preventDefault();
		setSections([...sections,
		{
			FieldsMap: [{ FormFieldName: "", OptimiserFieldName: "", IsNew: true }],
			ObjectName: "",
			IsNew: true,
			ObjectFields: [],
			SelectedOption: "option1",
			UniqueFields: [{ FormFieldName: '', OptimiserFieldName: '', IsNew: true }],
			validationMessages: {
				ObjectName: '',
				UniqueFields: [{ FormFieldName: '', OptimiserFieldName: '' }],
				FieldsMap: [{ FormFieldName: '', OptimiserFieldName: '' }],
			}
		}
		]);
	};
	return (
		<>
			<h2 className="text-primary mb-0">Update existing record</h2>
			<small className="text-gray">An existing record will be updated basis the fields mapped here</small>
			<form className="pt-2" onSubmit={handleSubmit}>
				{sections.map((section: any, sectionIndex: number) => (
					<FormSection
						key={sectionIndex}
						section={section}
						sectionIndex={sectionIndex}
						optimiserObjects={optimiserObjects}
						items={items}
						handleObjectChange={handleObjectChange}
						handleUniqueFieldsChange={handleUniqueFieldsChange}
						handleDeleteField={handleDeleteField}
						validationMessages={section.validationMessages}
						handleRadioChange={handleRadioChange}
						isFormDisabled={isFormDisabled}
						handleInputChange={handleInputChange}
						handleAddField={handleAddField}
						getPreviousSectionIds={getPreviousSectionIds}
						handleObjectDelete={handleObjectDelete}
						handleSliderChange={handleSliderChange}
						filteredObjectFields={filteredObjectFields}
					/>
				))}

				{/* <div className={"text-right pt-3" + " " + (isFormDisabled && "is-disabled")}>
					<button type="submit" className="btn btn-secondary add-object-btn" onClick={handleAddSection}>
						Add Object
					</button>
				</div> */}

				<hr />
				<div className="d-flex d-flex-2">
					<a className="back-btn" onClick={handleBackButton} href="#"><i className="fa fa-arrow-left"></i>Back</a>
					<div className={"text-right" + " " + (isFormDisabled && "is-disabled")} >
						<button className="btn-outline mr-2 with-border" onClick={resetCreatForm}>
							Cancel
						</button>
						<button type="submit" className="btn btn-primary">
							{isEdit ? "Save" : "Create"}
						</button>
					</div>

				</div>
			</form>
		</>
	);
};

export default UpdateRecord;
