import { CustomWidgetCollection, Serializer, Question, SvgRegistry, ItemValue } from "survey-core";
import { debounce } from "../../helpers";
import { localization } from "survey-creator-core";
import { setuid } from "process";
const translations = localization.getLocale("");

SvgRegistry.registerIconFromSvg("icon-terms", `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--!Font Awesome Pro 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M64 48H320c8.8 0 16 7.2 16 16V220.5c14.6-9.5 30.8-17 48-21.8V64c0-35.3-28.7-64-64-64H64C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c3.3 0 6.6-.3 9.7-.7c-17.9-12.8-33.3-28.8-45.3-47.3H64c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16zm56 80c-13.3 0-24 10.7-24 24s10.7 24 24 24H264c13.3 0 24-10.7 24-24s-10.7-24-24-24H120zm0 96c-13.3 0-24 10.7-24 24s10.7 24 24 24H264c13.3 0 24-10.7 24-24s-10.7-24-24-24H120zm0 96c-13.3 0-24 10.7-24 24s10.7 24 24 24h48c13.3 0 24-10.7 24-24s-10.7-24-24-24H120zm456 48a144 144 0 1 0 -288 0 144 144 0 1 0 288 0zm-76.7-43.3c6.2 6.2 6.2 16.4 0 22.6l-72 72c-6.2 6.2-16.4 6.2-22.6 0l-40-40c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0L416 385.4l60.7-60.7c6.2-6.2 16.4-6.2 22.6 0z"/></svg>`);
// SvgRegistry.registerIconFromSvg("icon-privacy", `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M256 0c4.6 0 9.2 1 13.4 2.9L457.7 82.8c22 9.3 38.4 31 38.3 57.2c-.5 99.2-41.3 280.7-213.6 363.2c-16.7 8-36.1 8-52.8 0C57.3 420.7 16.5 239.2 16 140c-.1-26.2 16.3-47.9 38.3-57.2L242.7 2.9C246.8 1 251.4 0 256 0zm0 66.8V444.8C394 378 431.1 230.1 432 141.4L256 66.8l0 0z"/></svg>`);

let fieldmaps: any = [
    {   text: 'Terms and Conditions', value: 'https://example.com/terms-conditions'},
    {   text: 'Privacy Policy', value: 'https://example.com/privacy-policy'}
];
let changeLabelPolicy: any;

CustomWidgetCollection.Instance.add({
    name: "termsconditions",
    iconName:'icon-terms',
    title: "Policy",
    htmlTemplate: `<div class="sd-item sd-selectbase__item sd-checkbox">
        <label class="sd-selectbase__label">
            <input type="checkbox" class="sd-visuallyhidden sd-item__control sd-checkbox__control">
            <span class="sd-item__decorator sd-checkbox__decorator"></span>
            <span class="sd-item__control-label">
                <span class="sv-string-viewer"></span>
            </span>
        </label>
    </div>`,
    widgetIsLoaded: () => true,
    isFit: (question: Question) => {
        if (question.getType() === "termsconditions") {
            question.titleLocation = 'hidden';
            question.hideNumber = true;
            question.renderAs = "checkbox";
            return true;
        }
        return false;
    },
    init: () => {
        Serializer.addClass("termsconditions", [], undefined, "empty");
        Serializer.addProperty('termsconditions', {
            name: 'labelText',
            type: 'string',
            displayName: 'Label Text',
            category: 'general',
            default: 'I agree to {Terms and Conditions} and {Privacy Policy}.',
            visibleIndex: 3,
        })

        let fieldOptions = fieldmaps.map((m: any) => {return ({text: m.text, value: m.value}) });
        Serializer.addProperty('termsconditions', {
            name: 'links',
            type: 'itemvalues',
            displayName: 'Links',
            category: 'general',
            default: fieldOptions
        })
    },
    afterRender: (question: Question, el: any) => {
        const checkbox = el.querySelector('input[type="checkbox"]');
        const sdCheckbox = el.querySelector('.sd-checkbox');
        const sdCheckboxDecorator = el.querySelector('.sd-checkbox__decorator');
        const termsConditionElement = el.querySelector('.sv-string-viewer');
        const initialMsg = { newValue: question.labelText, oldValue: question.labelText };

        translations.pehelp.labelText = "To create a link to your terms page, place the words you want to turn into a link inside curly brackets. For example, 'I agree to {terms}'."

        if (question.readOnly || question.isReadOnly) {
            checkbox.setAttribute('readonly', question.isReadOnly);
            checkbox.setAttribute('disabled', question.isReadOnly);
        } else {
            checkbox.removeAttribute('readonly');
            checkbox.removeAttribute('disabled');
        }

        // Function to create the SVG element
        const svgHtml = `<svg class="sd-item__svg sd-checkbox__svg">
                            <use xlink:href="#icon-v2check"></use>
                        </svg>
                        <span class="check"></span>`;
        // Function to update the checkbox state and appearance
        const updateCheckboxState = (isChecked: boolean) => {
            checkbox.checked = isChecked;
            sdCheckboxDecorator.innerHTML = isChecked ? svgHtml : '';
            if (isChecked) {
                sdCheckbox.classList.add('sd-item--checked', 'sd-checkbox--checked');
            } else {
                sdCheckbox.classList.remove('sd-item--checked', 'sd-checkbox--checked');
            }
        };
        // Set initial state of the checkbox
        updateCheckboxState(question.value === true);
        // Event listener for checkbox changes
        checkbox.addEventListener('change', () => {
            // Update the question value and checkbox appearance
            question.value = checkbox.checked;
            updateCheckboxState(checkbox.checked);
        });
        // Update the checkbox state and appearance when the question value changes
        question.valueChangedCallback = () => {
            updateCheckboxState(question.value === true);
        };

        
        const updateLinks = (options: any, match: any, placeholder: any, links: any, updatedText: any) => {   
            links.forEach((link: any) => {
                if (placeholder === link.text) {
                    updatedText = updatedText.replace(
                        match, 
                        `<a href="${link.value || '#'}" target="_blank" id="tp-${link.renderedId}">${link.text}</a>`
                        );
                }
            });
            return updatedText;
        }

        const extractMatches = (text: any) => (text ? text.match(/{[^{}]*}/g) || [] : []);
        const uniqueUpdatedLinks = (arr: any, prop: any) =>
        arr.reduce((acc: any, cur: any) =>
            acc.some((item: any) => item[prop] === cur[prop]) ? acc : [...acc, cur],
        []);

        const processMatches = (options: any, question: any) => {
            let matches = extractMatches(options?.newValue);
            let oldMatches = extractMatches(options?.oldValue);
        
            if (!matches.length && oldMatches.length) {
                oldMatches = extractMatches(question.labelText);
                matches = [...oldMatches];
                options.newValue = question.labelText;
            }
            let links = question.links || [];
            if (matches.length > 0) {
                let updatedText = options.newValue;
                let updatedLinks: any = [];
                for(let i=0; i<matches.length; i++) {
                    const placeholder = matches[i].slice(1, -1);
                    const oldPlaceholder = oldMatches[i]?.slice(1, -1) || placeholder;
        
                    const newLink = links.find((l: any) => l.text == placeholder);
                    const oldLink = links.find((l: any) => l.text == oldPlaceholder);
                    const checkInUpdatedLinks = updatedLinks.find((l: any) => l.text == placeholder)
                    if(oldLink && matches.length == oldMatches.length && newLink && checkInUpdatedLinks) { // to delete inside enclosed string
                        updatedLinks.push(new ItemValue(oldLink.value, placeholder));

                    } else if(oldLink && matches.length <= oldMatches.length && !checkInUpdatedLinks) {  // to delete enclosed string
                        const LinkUrl = newLink ? newLink.value : oldLink.value;
                        updatedLinks.push(new ItemValue(LinkUrl, placeholder));
                    } 
                    else if(!newLink && (matches.length >= oldMatches.length)) { // to gain new enclosed string
                        updatedLinks.push(new ItemValue(`https://example.com/${placeholder.toLowerCase().trim().replace(/\s+/g, "-")}`, placeholder));

                    } else if(!checkInUpdatedLinks) {  // to check for duplicate string
                        updatedLinks.push(new ItemValue(newLink.value, placeholder))
                    }

                    updatedText = updateLinks(options, matches[i], placeholder, updatedLinks, updatedText);
                } 
                updatedLinks = uniqueUpdatedLinks(updatedLinks, 'text')
                question.links = updatedLinks; 
                // Serializer.getProperty('termsconditions', 'links').defaultValueValue = updatedLinks; 
                termsConditionElement.innerHTML = updatedText
            }  
            else {
                question.links = [];
                Serializer.getProperty('termsconditions', 'links').defaultValueValue = []; 
                termsConditionElement.innerHTML = options.newValue;
            }
        }

        changeLabelPolicy = (sender: any, options: any) => {
            if(options.name === 'labelText') {
                processMatches(options, question)
            }
        }
        setTimeout(() => {
            processMatches(initialMsg, question);
        }, 100);
        question.onPropertyChanged.add(changeLabelPolicy)

        question.onItemValuePropertyChanged.add((sender, options) => {
            if(options.propertyName == 'links') {
                let setElement = document.querySelector(`a[href="${options.obj.value}"]`)
                if(options.name == 'value' && setElement) {
                    setElement?.setAttribute('href', options.newValue)
                } else if(options.name == 'text' && setElement) {
                    setElement.innerHTML = options.newValue;
                    question.labelText = question.labelText.replace(new RegExp(`{${options.oldValue}}`, 'g'), `{${options.newValue}}`)               
                }
            }
        })
    },

    willUnmount: (question: Question, el: any) => {
        question.onPropertyChanged.remove(changeLabelPolicy);
    }
}, "customtype");
// CustomWidgetCollection.Instance.add({
//     name: "privacypolicy",
//     iconName:'icon-privacy',
//     title: "Privacy Policy",
//     htmlTemplate: `<div class="sd-item sd-selectbase__item sd-checkbox">
//         <label class="sd-selectbase__label">
//             <input type="checkbox" class="sd-visuallyhidden sd-item__control sd-checkbox__control">
//             <span class="sd-item__decorator sd-checkbox__decorator"></span>
//             <span class="sd-item__control-label">
//                 <span class="sv-string-viewer">I agree to <a href="#" target="_blank" id="privacyLink">Privacy Policy</a></span>
//             </span>
//         </label>
//     </div>`,
//     widgetIsLoaded: () => true,
//     isFit: (question: Question) => {
//         if (question.getType() === "privacypolicy") {
//             question.titleLocation = 'hidden';
//             question.hideNumber = true;
//             question.renderAs = "checkbox";
//             return true;
//         }
//         return false;
//     },
//     init: () => {
//         Serializer.addClass("privacypolicy", [], undefined, "empty");
//         Serializer.addProperty('privacypolicy', {
//             name: `privacyLink`,
//             type: 'string',
//             displayName:'Privacy Link',
//             category: 'general',
//             default: 'https://example.com/privacy-policy'
//         });
//     },
//     afterRender: (question: Question, el: any) => {
//         const checkbox = el.querySelector('input[type="checkbox"]');
//         const sdCheckbox = el.querySelector('.sd-checkbox');
//         const sdCheckboxDecorator = el.querySelector('.sd-checkbox__decorator');
//         const privacyLinkElement = el.querySelector('#privacyLink');

//         if (question.readOnly || question.isReadOnly) {
//             checkbox.setAttribute('readonly', question.isReadOnly);
//             checkbox.setAttribute('disabled', question.isReadOnly);
//         } else {
//             checkbox.removeAttribute('readonly');
//             checkbox.removeAttribute('disabled');
//         }
        
//         // Function to create the SVG element
//         const svgHtml = `<svg class="sd-item__svg sd-checkbox__svg">
//                             <use xlink:href="#icon-v2check"></use>
//                         </svg>
//                         <span class="check"></span>`;
//         // Function to update the checkbox state and appearance
//         const updateCheckboxState = (isChecked: boolean) => {
//             checkbox.checked = isChecked;
//             sdCheckboxDecorator.innerHTML = isChecked ? svgHtml : '';
//             if (isChecked) {
//                 sdCheckbox.classList.add('sd-item--checked', 'sd-checkbox--checked');
//             } else {
//                 sdCheckbox.classList.remove('sd-item--checked', 'sd-checkbox--checked');
//             }
//         };
//         // Set initial state of the checkbox
//         updateCheckboxState(question.value === true);
//         // Event listener for checkbox changes
//         checkbox.addEventListener('change', () => {
//             // Update the question value and checkbox appearance
//             question.value = checkbox.checked;
//             updateCheckboxState(checkbox.checked);
//         });
//         // Update the checkbox state and appearance when the question value changes
//         question.valueChangedCallback = () => {
//             updateCheckboxState(question.value === true);
//         };
//         // Update the link based on the question property
//         let privacyLink = question.getPropertyValue('privacyLink');
//         if (privacyLink) {
//             privacyLinkElement.href = privacyLink;
//         } else {
//             privacyLinkElement.removeAttribute('href');
//         }
//     }
// }, "customtype");