import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { setFormData, type RootState, setCheck } from "../../store";
import useAxios from "../../hooks/useAxios";
import { ConfirmWithYesAndNo, Success } from "../../config/swal";
import { Action } from 'survey-core'

const PublishComponent = ({ setFormStatus, creator }: any) => {
    const config = useSelector((state: RootState) => state.project.projectConfig);
    const isCheck = useSelector((state: RootState) => state.project.isCheck);
    const { _id, LinkSharing, ShowOnPageBuilder, FormID, Status } = useSelector((state: RootState) => state.project.formData);
    const isPublished = Status === 'Published';
    const [isCopied, setIsCopied] = useState(false);
    const [link, setLink] = useState(LinkSharing.Link);
    const [showOnEditor, setShowOnEditor] = useState(ShowOnPageBuilder);
    const [linkShareEnabled, setLinkShareEnabled] = useState(LinkSharing.Enabled);

    const [linkError, setLinkError] = useState('');

    const axios = useAxios();
    const dispatch = useDispatch();
    const baseDomain = config.EnvType == 'production' ? config.Domain : config.CDNName;


    useEffect(() => {
        updateStatus();
        return (() => {
            const saveBtn = creator.toolbarItems.find((item: any) => item.id == "save-status");
            saveBtn.visible = false;
        })
    }, [isCheck])

    const updateStatus = () => {
        const saveBtn = creator.toolbarItems.find((item: any) => item.id == "save-status");
        saveBtn.visible = true;
        saveBtn.iconName = isCheck ? "icon-moderncheck" : "icon-more";
        saveBtn.css = isCheck ? "btn-outline-secondary active" : "btn-outline-secondary";
    }


    const changeFormStatus = async () => {
        try {
            if (isPublished && (showOnEditor || linkShareEnabled)) {
                let msg = 'on ';
                msg += showOnEditor ? 'website pages' : '';
                msg += (showOnEditor && linkShareEnabled) ? ' and ' : '';
                msg += linkShareEnabled ? 'on the link' : '';

                const { isConfirmed } = await ConfirmWithYesAndNo({
                    html: `<span style='font-size:15px; color:#686868;'>This form is published ${msg}. Are you sure you would like to unpublish it?</span>`
                });
                if (!isConfirmed) return;
            }
            const status = isPublished ? 'Unpublished' : 'Published';
            await axios.put('forms/status/' + _id, { Status: status, LinkSharing });
            dispatch(setFormData({ Status: status }));
            setFormStatus(status)
            Success({ title: !isPublished ? 'Form published' : 'Form unpublished' });
        } catch (error) {
            console.log(error);

        }
    }

    const changeFormLink = async () => {
        try {
            setLinkError('')
            const { message }: any = await axios.put('forms/link/' + _id, { Link: link, FormID });
            if (message !== 'success') return setLinkError(message);
            dispatch(setFormData({ LinkSharing: { ...LinkSharing, Link: link } }));
        } catch (error) {
            console.log(error);
        }
    }

    const copyUrl = async () => {
        try {
            let fullURL = baseDomain + '/' + LinkSharing.Link;
            if (isCopied) return;
            if (navigator.clipboard) {
                await navigator.clipboard.writeText(fullURL)
            } else {
                const textArea = document.createElement("textarea");
                textArea.value = fullURL;
                document.body.appendChild(textArea);
                textArea.select();
                document.execCommand("copy");
                textArea.remove();
            }
            setIsCopied(true);
            setTimeout(() => {
                setIsCopied(false);
            }, 2000);
        } catch (error) {
            setIsCopied(false);
            console.log(error);
 
        }
    }
    


    const validateAndFormatUrlSegment = (input: string) => {
        let formattedInput = input.toLowerCase();
        formattedInput = formattedInput.replace(/[^a-z0-9\-_\/]/g, '');
        formattedInput = formattedInput.replace(/^\//, '');
        formattedInput = formattedInput.replace(/\/{2,}/g, '/');
        return formattedInput;
    };

    const onLinkChange = (event: any) => {
        const input = event.target.value;
        const formattedInput = validateAndFormatUrlSegment(input);
        setLink(formattedInput);
    }

    const onLinkBlur = (e: any) => {
        if (!link) return setLinkError('The url segment is required');
        if (LinkSharing.Link !== link) changeFormLink();
    }

    const onShowPBChange = async (e: any) => {
        try {
            const checked = e.target.checked;
            setShowOnEditor(checked);
            if (!checked && isPublished) {
                const { isConfirmed } = await ConfirmWithYesAndNo({
                    html: `<span style='font-size:15px; color:#686868;'>This form is published on website pages. Are you sure you would like to unpublish it?</span>`
                });
                if (!isConfirmed) {
                    setShowOnEditor(true);
                    return;
                }
            }
            dispatch(setCheck(false));
            await axios.put('forms/form-setting', { FormID, _id, Status, ShowOnPageBuilder: checked });
            dispatch(setFormData({ ShowOnPageBuilder: checked }))
            dispatch(setCheck(true));
            // Success({ title: 'Form updated' });
        } catch (error) {
            console.log(error);
        }
    }

    const linkShareChange = async (e: any) => {
        try {
            const checked = e.target.checked;
            setLinkShareEnabled(checked);
            if (!checked && isPublished) {
                const { isConfirmed } = await ConfirmWithYesAndNo({
                    html: `<span style='font-size:15px; color:#686868;'>This form is published on the link. Are you sure you would like to unpublish it?</span>`
                });
                if (!isConfirmed) {
                    setLinkShareEnabled(true);
                    return;
                }
            }
            dispatch(setCheck(false));
            await axios.put('forms/form-setting', { FormID, _id, Status, 'LinkSharing.Enabled': checked });
            dispatch(setFormData({ LinkSharing: { ...LinkSharing, Enabled: checked } }))
            dispatch(setCheck(true));
            // Success({ title: 'Form updated' });
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            <div className="svc-tab-designer">
                <div className="tab-content">

                    <div className="settings-form mt-2 w-70 border rounded" style={{ padding: '4px 15px' }}>
                        <div className="mt-2 d-flex" style={{ justifyContent: 'space-between' }}>
                            <div className="form-group">
                                <label>Form Status: &nbsp; <span className={isPublished ? "chip published" : "chip unpublished"}>{isPublished ? 'Published' : 'Unpublished'}</span></label>
                            </div>
                            <button onClick={changeFormStatus} type="button" className={'btn slick' + `${!isPublished ? ' success' : ' danger'}`}>{isPublished ? 'Unpublish' : 'Publish'}</button>
                        </div>
                    </div>
                   { config.CompanyType != "FormsOnly" && <div className="settings-form mt-2 w-70 border rounded pb-setting">
                            <div className="form-cb-group mb-2">
                                <label>Show on Page Builder </label>
                                <label className="switch">
                                    <input checked={showOnEditor} onChange={onShowPBChange} name='ShowOnPageBuilder' id='ShowOnPageBuilder' type="checkbox" />
                                    <span className="slider round"></span>
                                </label>
                                <div className="small mb-2">This form will show in the form list while working on Page Builder.</div>
                            </div>
                        </div> 
                    }

                    <div className="settings-form mt-2 w-70 border rounded pb-setting">
                        <div className="form-cb-group mb-2">
                            <label>Form Link to Share </label>
                            <label className="switch">
                                <input checked={LinkSharing.Enabled} onChange={linkShareChange} type="checkbox" />
                                <span className="slider round"></span>
                            </label>
                            <div className="small mb-2">Share with people to use the form with this link. Please note, once published, form link cannot be changed</div>
                        </div>

                        {LinkSharing.Enabled ? <div className="d-flex form-group">
                            <div className="domain-url">{baseDomain}/</div>
                            <input type="text" disabled={Status !== 'Draft'} className="form-control" onBlur={onLinkBlur} value={link} onChange={onLinkChange} placeholder="Eg: form/my-form" />
                            <div onClick={copyUrl} className="domain-url copy"><i className={!isCopied ? 'fa fa-copy text-primary' : 'fa fa-check-circle text-success'}></i></div>
                        </div> : ''}
                        {LinkSharing.Enabled && linkError && <span className='error'>{linkError}</span>}
                    </div>
                </div>
            </div>
        </>
    )
}

export default PublishComponent;